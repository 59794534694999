import React from "react";
import { IntlProvider } from "react-intl";
import { FALLBACK_SIMPLIFIED_LOCALE } from "shared/constants";
import { PageContext } from "shared/types/page";

interface Props {
  pageContext: PageContext;
}

const Wrapper: React.FC<Props> = ({ children, pageContext }) => {
  const { intl } = pageContext;

  return (
    <IntlProvider
      locale={intl?.locale.short || FALLBACK_SIMPLIFIED_LOCALE}
      messages={intl?.messages}
    >
      {children}
    </IntlProvider>
  );
};

export default Wrapper;
