import { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";

import Wrapper from "./Wrapper";

export const wrapPageElement = ({
  element,
}: WrapPageElementBrowserArgs<unknown>): React.ReactNode => {
  return <Wrapper>{element}</Wrapper>;
};
