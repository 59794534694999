import React from "react";
import { PageContext } from "shared/types/page";

import { ReCaptchaContextProvider } from "./src/hooks/useReCaptcha";

interface Props {
  pageContext: PageContext;
}

const Wrapper: React.FC<Props> = ({ children, pageContext }) => {
  return (
    <ReCaptchaContextProvider pageContext={pageContext}>
      {children}
    </ReCaptchaContextProvider>
  );
};

export default Wrapper;
