/** @jsx jsx */
import { FC, memo, ReactNode, useMemo } from "react";
import { jsx, Theme, ThemeProvider as ThemeProviderOriginal } from "theme-ui";

import { getMostReadable, UIColors } from "../../helpers/colors";
import useThemeUI, { Color } from "../../hooks/useThemeUI";

export const overrideThemeColors = (
  theme: Theme,
  colors: Theme["colors"]
): Theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...colors,
    },
  };
};

type Props = {
  children?: ReactNode;
  colors: ((rawColors: Color | undefined) => Partial<Color>) | Partial<Color>;
  useAltFallback?: boolean;
};

const ThemeProvider: FC<Props> = ({
  children,
  colors,
  useAltFallback = false,
}) => {
  const { theme } = useThemeUI();

  const computedTheme: Theme = useMemo(() => {
    const computedColors =
      typeof colors === "function" ? colors(theme.rawColors) : colors;

    if (computedColors && "background" in computedColors) {
      // Ensure readable text on new background color
      computedColors.text = getMostReadable(
        computedColors.background,
        useAltFallback
          ? [UIColors.lightAlt.toHexString(), UIColors.darkAlt.toHexString()]
          : undefined
      ).toHexString();
    }

    return overrideThemeColors(theme, computedColors);
  }, [colors, theme]);

  return (
    <ThemeProviderOriginal theme={computedTheme}>
      {children}
    </ThemeProviderOriginal>
  );
};

export default memo(ThemeProvider);
