import React, { Fragment, memo, useContext, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { PageContext } from "shared/types/page";

type Context = {
  reCaptchaKey: string | null;
  setReCaptchaKey: (reCaptchaKey: string | null) => void;
};

export const ReCaptchaContext = React.createContext<Context>({
  reCaptchaKey: null,
  setReCaptchaKey: () => undefined,
});

export const ReCaptchaContextProvider: React.FC<{
  pageContext: PageContext;
}> = memo(({ children, pageContext }) => {
  if (!pageContext.intl) {
    return <Fragment>{children}</Fragment>;
  }

  const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);

  const nextContext = {
    setReCaptchaKey: (nextReCaptchaKey) => {
      const IS_LIVE_PREVIEW = !!window.opener;
      if (!IS_LIVE_PREVIEW) {
        setReCaptchaKey(nextReCaptchaKey);
      }
    },
    reCaptchaKey,
  } as Context;

  return (
    <ReCaptchaContext.Provider value={nextContext}>
      {reCaptchaKey ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={reCaptchaKey}
          language={pageContext.intl.locale.long}
          scriptProps={{
            async: true,
            defer: true,
          }}
        >
          {children}
        </GoogleReCaptchaProvider>
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </ReCaptchaContext.Provider>
  );
});

const useReCaptcha = (): Context => {
  const context = useContext(ReCaptchaContext);
  return context;
};

export default useReCaptcha;
