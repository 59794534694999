/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import React, { memo, useEffect } from "react";
import { Helmet } from "react-helmet";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import type { AdsConfigQuery } from "../../../__generated__/gatsby.types";
import {
  CMP_ID,
  WBADS_GTM_ID,
  WBADS_NETWORK,
  WBADS_SITE,
} from "../../../constants";

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
    didomiConfig?: {
      regulations?: {
        ccpa?: {
          enabled?: boolean;
        };
      };
    };
    jad: {
      cmd: Array<() => void>;
      public?: {
        setConfig?: (arguments: {
          page: string;
          pagePositions: ("header" | "interstitial")[];
          targeting: {
            page: string;
            theaterID: string;
            theater: string;
            theaterGroupID: string;
            theaterGroup: string;
            adType: "captif-et-hors-captif" | "captif";
          };
          elementsMapping: {
            header: string;
            interstitial: string;
          };
          interstitialOnFirstPageEnabled: boolean;
        }) => void;
        loadPositions?: () => void;
        displayPositions?: () => void;
      };
    };
  }
}

// GTM data layer
if (global.window) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "gtm.js",
    "gtm.start": new Date().getTime(),
  });
  window.didomiConfig = window.didomiConfig || {};
  window.didomiConfig.regulations = {
    ...window.didomiConfig.regulations,
    ccpa: {
      ...window.didomiConfig.regulations?.ccpa,
      enabled: true,
    },
  };
}

type Props = {
  pageTitle: string;
};

const ADS: React.FC<Props> = ({ pageTitle }) => {
  const pageContext = usePageContext();
  const wbAdsSite = WBADS_SITE[pageContext.intl.country.code.toLowerCase()];

  const [selectedTheaterId] = useSelectedTheaterId();

  const data = useStaticQuery<AdsConfigQuery>(graphql`
    query ADSConfig {
      settings {
        adsDisplay
        adsLocation
        circuit {
          id
          name
        }
      }
      allTheater(sort: { fields: name, order: ASC }) {
        nodes {
          id
          name
        }
      }
    }
  `);

  const selectedTheater = pageContext.isSingleLocation
    ? data.allTheater.nodes[0]
    : data.allTheater.nodes.find((theater) => theater.id === selectedTheaterId);

  useEffect(() => {
    if (!wbAdsSite || !data.settings?.adsDisplay) {
      return;
    }

    window.jad = window.jad || {};
    window.jad.cmd = window.jad.cmd || [];

    // CAPTIVE: ads related to the "movie" domain
    // PROGRAMMATIC: broad ad targeting, can relate to anything
    const siteType =
      data.settings.adsDisplay === "PROGRAMMATIC" ? "allads" : "restrictedads";

    window.jad.cmd.push(() => {
      window.jad.public?.setConfig?.({
        page: `/${WBADS_NETWORK}/${wbAdsSite}/${siteType}`,
        pagePositions: ["header", "interstitial"],
        elementsMapping: {
          header: "HeaderTakeoverContainer",
          interstitial: "InterstitialContainer",
        },
        targeting: {
          page: pageTitle,
          theater: selectedTheater?.name || "",
          theaterID: selectedTheater?.id || "",
          theaterGroup: data.settings?.circuit?.name || "",
          theaterGroupID: data.settings?.circuit?.id || "",
          adType:
            siteType === "restrictedads" ? "captif" : "captif-et-hors-captif",
        },
        interstitialOnFirstPageEnabled: true,
      });

      window.jad.public?.loadPositions?.();
      window.jad.public?.displayPositions?.();
    });
  }, []);

  return (
    <Helmet>
      {/* Consent Management Platform */}
      <script type="text/javascript">
        {`(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
	        else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
	        var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
	        if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
            var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.defer=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("${CMP_ID}")})();`}
      </script>

      {/* Webedia ADS GTM */}
      {data.settings?.adsDisplay && (
        <script
          async
          src={`https://www.googletagmanager.com/gtm.js?id=${WBADS_GTM_ID}`}
        />
      )}

      {/* Webedia ADS library */}
      {data.settings?.adsDisplay && (
        <script
          async
          src={`https://cdn.api.getjad.io/library/${WBADS_NETWORK}/${wbAdsSite}`}
        />
      )}
    </Helmet>
  );
};

export const ADSContainer: Record<
  "Header" | "Interstitial",
  React.FC<unknown>
> = {
  Header: () => <div id={"HeaderTakeoverContainer"} />,
  Interstitial: () => <div id={"InterstitialContainer"} />,
};

export default memo(ADS);
