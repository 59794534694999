module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../gatsby-plugins/gatsby-plugin-intl/gatsby-browser.tsx'),
      options: {"plugins":[],"theme":{"colors":{"palette":{"muted":"#773059","accent":"#FFF2BD","primary":"#B2255E","highlight":"#3B6287","background":"#302A42","footerBackground":"#3B6287","headerBackground":"#DDDCD9"}},"fonts":{"body":"Work Sans","heading":"Work Sans"},"fontWeights":{"body":400,"heading":700,"bold":700},"fontStyles":{"body":"normal","heading":"normal"}},"googleFonts":[{"font":"Work Sans","weights":["400","700"]},{"font":"Work Sans","weights":["700"]}],"country":{"code":"us","iso31661A2":"US","iso31661A3":"USA"},"locale":"en-US","name":"Cinema West - Test Alex","pathPrefix":"/","websiteId":"V2Vic2l0ZU1hbmFnZXJXZWJzaXRlOjkyNDA4MzYxLTcxYzctNDJiMS04NzZlLWI3ZWY2NDE5OWZlZA==","trackers":{},"isSingleLocation":false,"circuitId":"Q2hhaW46MTAwNjk5"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../gatsby-modules/gatsby-theme-loyalty/gatsby-browser.tsx'),
      options: {"plugins":[],"theme":{"colors":{"palette":{"muted":"#773059","accent":"#FFF2BD","primary":"#B2255E","highlight":"#3B6287","background":"#302A42","footerBackground":"#3B6287","headerBackground":"#DDDCD9"}},"fonts":{"body":"Work Sans","heading":"Work Sans"},"fontWeights":{"body":400,"heading":700,"bold":700},"fontStyles":{"body":"normal","heading":"normal"}},"googleFonts":[{"font":"Work Sans","weights":["400","700"]},{"font":"Work Sans","weights":["700"]}],"country":{"code":"us","iso31661A2":"US","iso31661A3":"USA"},"locale":"en-US","name":"Cinema West - Test Alex","pathPrefix":"/","websiteId":"V2Vic2l0ZU1hbmFnZXJXZWJzaXRlOjkyNDA4MzYxLTcxYzctNDJiMS04NzZlLWI3ZWY2NDE5OWZlZA==","trackers":{},"isSingleLocation":false,"circuitId":"Q2hhaW46MTAwNjk5"},
    },{
      plugin: require('../../../../gatsby-modules/gatsby-theme-ads/gatsby-browser.tsx'),
      options: {"plugins":[],"theme":{"colors":{"palette":{"muted":"#773059","accent":"#FFF2BD","primary":"#B2255E","highlight":"#3B6287","background":"#302A42","footerBackground":"#3B6287","headerBackground":"#DDDCD9"}},"fonts":{"body":"Work Sans","heading":"Work Sans"},"fontWeights":{"body":400,"heading":700,"bold":700},"fontStyles":{"body":"normal","heading":"normal"}},"googleFonts":[{"font":"Work Sans","weights":["400","700"]},{"font":"Work Sans","weights":["700"]}],"country":{"code":"us","iso31661A2":"US","iso31661A3":"USA"},"locale":"en-US","name":"Cinema West - Test Alex","pathPrefix":"/","websiteId":"V2Vic2l0ZU1hbmFnZXJXZWJzaXRlOjkyNDA4MzYxLTcxYzctNDJiMS04NzZlLWI3ZWY2NDE5OWZlZA==","trackers":{},"isSingleLocation":false,"circuitId":"Q2hhaW46MTAwNjk5"},
    },{
      plugin: require('../../../../gatsby-modules/gatsby-theme-form/gatsby-browser.tsx'),
      options: {"plugins":[],"theme":{"colors":{"palette":{"muted":"#773059","accent":"#FFF2BD","primary":"#B2255E","highlight":"#3B6287","background":"#302A42","footerBackground":"#3B6287","headerBackground":"#DDDCD9"}},"fonts":{"body":"Work Sans","heading":"Work Sans"},"fontWeights":{"body":400,"heading":700,"bold":700},"fontStyles":{"body":"normal","heading":"normal"}},"googleFonts":[{"font":"Work Sans","weights":["400","700"]},{"font":"Work Sans","weights":["700"]}],"country":{"code":"us","iso31661A2":"US","iso31661A3":"USA"},"locale":"en-US","name":"Cinema West - Test Alex","pathPrefix":"/","websiteId":"V2Vic2l0ZU1hbmFnZXJXZWJzaXRlOjkyNDA4MzYxLTcxYzctNDJiMS04NzZlLWI3ZWY2NDE5OWZlZA==","trackers":{},"isSingleLocation":false,"circuitId":"Q2hhaW46MTAwNjk5"},
    },{
      plugin: require('../../../../gatsby-modules/gatsby-theme-trackers/gatsby-browser.tsx'),
      options: {"plugins":[],"theme":{"colors":{"palette":{"muted":"#773059","accent":"#FFF2BD","primary":"#B2255E","highlight":"#3B6287","background":"#302A42","footerBackground":"#3B6287","headerBackground":"#DDDCD9"}},"fonts":{"body":"Work Sans","heading":"Work Sans"},"fontWeights":{"body":400,"heading":700,"bold":700},"fontStyles":{"body":"normal","heading":"normal"}},"googleFonts":[{"font":"Work Sans","weights":["400","700"]},{"font":"Work Sans","weights":["700"]}],"country":{"code":"us","iso31661A2":"US","iso31661A3":"USA"},"locale":"en-US","name":"Cinema West - Test Alex","pathPrefix":"/","websiteId":"V2Vic2l0ZU1hbmFnZXJXZWJzaXRlOjkyNDA4MzYxLTcxYzctNDJiMS04NzZlLWI3ZWY2NDE5OWZlZA==","trackers":{},"isSingleLocation":false,"circuitId":"Q2hhaW46MTAwNjk5"},
    },{
      plugin: require('../../../../gatsby-templates/gatsby-theme-fincher-template/gatsby-browser.tsx'),
      options: {"plugins":[],"theme":{"colors":{"palette":{"muted":"#773059","accent":"#FFF2BD","primary":"#B2255E","highlight":"#3B6287","background":"#302A42","footerBackground":"#3B6287","headerBackground":"#DDDCD9"}},"fonts":{"body":"Work Sans","heading":"Work Sans"},"fontWeights":{"body":400,"heading":700,"bold":700},"fontStyles":{"body":"normal","heading":"normal"}},"googleFonts":[{"font":"Work Sans","weights":["400","700"]},{"font":"Work Sans","weights":["700"]}],"country":{"code":"us","iso31661A2":"US","iso31661A3":"USA"},"locale":"en-US","name":"Cinema West - Test Alex","pathPrefix":"/","websiteId":"V2Vic2l0ZU1hbmFnZXJXZWJzaXRlOjkyNDA4MzYxLTcxYzctNDJiMS04NzZlLWI3ZWY2NDE5OWZlZA==","trackers":{},"isSingleLocation":false,"circuitId":"Q2hhaW46MTAwNjk5"},
    }]
