import { GatsbyBrowser, WrapPageElementBrowserArgs } from "gatsby";
import React from "react";
import { PluginOptions } from "shared/types/config";
import { PageContext } from "shared/types/page";

import Wrapper from "./Wrapper";

export const wrapPageElement = (
  { element }: WrapPageElementBrowserArgs<unknown, PageContext>,
  pluginOptions: PluginOptions
): React.ReactNode => {
  return <Wrapper trackers={pluginOptions.trackers}>{element}</Wrapper>;
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  if (typeof window.Analytics !== "undefined") {
    // On every route change fire this code!!
    window.Analytics.page();
  }
};
