// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import googleAnalytics from "@analytics/google-analytics";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import googleTagManager from "@analytics/google-tag-manager";
import analytics from "analytics";
import { PluginOptionsTrackers } from "shared/types/config";

import {
  ANALYTICS_PLUGINS,
  CUSTOM_DIMENSIONS,
  GOOGLE_ANALYTICS_DIDOMI_ID,
  GOOGLE_ANALYTICS_INTERNAL_ID,
  GOOGLE_MARKETING_DIDOMI_ID,
} from "./constants";
import type {
  DidomiConsentResult,
  DidomiInstance,
  DidomiRegulation,
} from "./declarations";

export const initTrackers = async (
  trackers: PluginOptionsTrackers | undefined,
  consents: boolean
): Promise<void> => {
  const plugins: Exclude<
    Parameters<typeof analytics>[0]["plugins"],
    undefined
  > = [];

  // Unload existing trackers if no consents
  if (!consents && window.Analytics) {
    // @ts-expect-error because this library is badly typed
    await window.Analytics.plugins.disable(Object.values(ANALYTICS_PLUGINS));
  }

  if (consents) {
    const sharedGoogleAnalyticsConfig = {
      // A key recommendation for GDPR compliance is to use the Google Analytics feature anonymizeIP
      // https://support.google.com/analytics/answer/2763052?hl=en
      anonymizeIp: true,
      setCustomDimensionsToPage: false,
      customDimensions: CUSTOM_DIMENSIONS,
      cookieConfig: {
        cookieExpires: 34164000,
        cookieUpdate: false,
      },
    };

    if (trackers?.google_analytics) {
      // Google Analytics tracking for the website owner
      plugins.push({
        ...googleAnalytics({
          ...sharedGoogleAnalyticsConfig,
          instanceName: "googleanalyticsowner",
          trackingId: trackers.google_analytics,
        }),
        name: ANALYTICS_PLUGINS.GOOGLE_ANALYTICS_OWNER,
      });
    }

    if (trackers?.google_tag_manager) {
      // Google Tag Manager for the website owner
      plugins.push({
        ...googleTagManager(
          {
            containerId: trackers.google_tag_manager,
          },
          {
            name: "google-tag-manager-owner",
          }
        ),
        name: ANALYTICS_PLUGINS.GOOGLE_TAG_MANAGER_OWNER,
      });
    }

    // Google Analytics tracking for internal usage
    plugins.push({
      ...googleAnalytics({
        ...sharedGoogleAnalyticsConfig,
        instanceName: "googleanalyticsinternal",
        trackingId: GOOGLE_ANALYTICS_INTERNAL_ID,
      }),
      name: ANALYTICS_PLUGINS.GOOGLE_ANALYTICS_INTERNAL,
    });
  }

  window.Analytics = analytics({ plugins });
};

export const getConsents = (
  didomiInstance: DidomiInstance | null | undefined
): boolean | undefined => {
  let regulation: DidomiRegulation | null = null;

  if (didomiInstance?.isRegulationApplied?.("ccpa")) {
    regulation = "ccpa";
  }

  if (didomiInstance?.isRegulationApplied?.("gdpr")) {
    regulation = "gdpr";
  }

  switch (regulation) {
    case "gdpr": {
      let nextConsents: [DidomiConsentResult, DidomiConsentResult] = [
        undefined,
        undefined,
      ];

      if (didomiInstance?.isConsentRequired?.()) {
        const analyticsConsent = didomiInstance.getUserConsentStatusForVendor?.(
          GOOGLE_ANALYTICS_DIDOMI_ID
        );
        const marketingConsent = didomiInstance.getUserConsentStatusForVendor?.(
          GOOGLE_MARKETING_DIDOMI_ID
        );

        nextConsents = [analyticsConsent, marketingConsent];
      } else {
        nextConsents = [true, true];
      }

      if (
        nextConsents.some((consent) => {
          return consent === false;
        })
      ) {
        return false;
      }

      if (
        nextConsents.every((consent) => {
          return consent === true;
        })
      ) {
        return true;
      }

      return undefined;
    }

    case "ccpa": {
      return !(didomiInstance?.CCPA?.getDoNotSellStatus?.() || false);
    }

    default:
      return true;
  }
};
