import { AnalyticsCustomDimension } from "shared/types/config";

const GOOGLE_ANALYTICS_INTERNAL_ID_PROD = "UA-167118745-1";
const GOOGLE_ANALYTICS_INTERNAL_ID_DEV = "UA-167118745-2";
export const GOOGLE_ANALYTICS_INTERNAL_ID =
  process.env.NODE_ENV === "production"
    ? GOOGLE_ANALYTICS_INTERNAL_ID_PROD
    : GOOGLE_ANALYTICS_INTERNAL_ID_DEV;

export const GOOGLE_ANALYTICS_DIDOMI_ID = "c:googleana-XMqagawa";
export const GOOGLE_MARKETING_DIDOMI_ID = "google";

// Do not swap those keys!
export const CUSTOM_DIMENSIONS: Record<AnalyticsCustomDimension, string> = {
  theaterName: "dimension1",
  theaterId: "dimension2",
  movieTitle: "dimension3",
  movieId: "dimension4",
};

export const ANALYTICS_PLUGINS = {
  GOOGLE_ANALYTICS_OWNER: "google-analytics-owner",
  GOOGLE_ANALYTICS_INTERNAL: "google-analytics-internal",
  GOOGLE_TAG_MANAGER_OWNER: "google-tag-manager-owner",
};
