import { Global } from "@emotion/react";
import React, { useState } from "react";
import ThemeProvider from "shared/components/ThemeProvider";
import URQLProvider from "shared/components/URQLProvider";
import { getMostReadable, getTinyColor } from "shared/helpers/colors";
import useColorSchemePreview from "shared/hooks/useColorSchemePreview";
import { ColorScheme } from "shared/hooks/useColorSchemePreview/types";
import { PageContextProvider } from "shared/hooks/usePageContext";
import { PageContext } from "shared/types/page";

interface Props {
  pageContext: PageContext;
}

const Wrapper: React.FC<Props> = ({ children, pageContext }) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme | null>(null);

  useColorSchemePreview((nextColorScheme) => {
    setColorScheme(nextColorScheme);
  });

  return (
    <ThemeProvider
      colors={(rawColors) => {
        return {
          primary: colorScheme?.palette?.primary || rawColors?.primary,
          accent: colorScheme?.palette?.accent || rawColors?.accent,
          highlight: colorScheme?.palette?.highlight || rawColors?.highlight,
          muted: colorScheme?.palette?.muted || rawColors?.muted,
          background: colorScheme?.palette?.background || rawColors?.background,
          footerBackground:
            colorScheme?.palette?.footerBackground ||
            rawColors?.footerBackground,
          headerBackground:
            colorScheme?.palette?.headerBackground ||
            rawColors?.headerBackground,
        };
      }}
    >
      <PageContextProvider pageContext={pageContext} templateName={"fincher"}>
        <Global
          styles={() => {
            const bg = colorScheme?.palette?.background
              ? getTinyColor(colorScheme?.palette?.background)
              : undefined;

            return {
              html: bg
                ? {
                    backgroundColor: bg.isLight()
                      ? bg.shade(6).toHexString()
                      : bg.tint(6).toHexString(),
                    color: getMostReadable(
                      colorScheme?.palette?.background
                    ).toHexString(),
                  }
                : undefined,
              "body, #___gatsby, #gatsby-focus-wrapper, #gatsby-focus-wrapper > div[data-themeui-nested-provider]":
                {
                  height: "100%",
                },
            };
          }}
        />
        <URQLProvider
          url={pageContext.allocineApiUrl}
          token={pageContext.allocineApiToken}
        >
          {children}
          <div id="modal-root"></div>
        </URQLProvider>
      </PageContextProvider>
    </ThemeProvider>
  );
};

export default Wrapper;
