import React, { Fragment, memo, useContext } from "react";

import { FALLBACK_PAGE_CONTEXT } from "../constants";
import { TemplateName } from "../types/config";
import { PageContext as PageContextType, PageContextIntl } from "../types/page";

type PageContextFromHook = PageContextType & {
  intl: PageContextIntl;
  templateName: TemplateName;
};

const DEFAULT_TEMPLATE_NAME: TemplateName = "mcdaniel";

export const PageContext = React.createContext<PageContextFromHook>({
  ...FALLBACK_PAGE_CONTEXT,
  templateName: DEFAULT_TEMPLATE_NAME,
} as PageContextFromHook);

export const PageContextProvider: React.FC<{
  pageContext: PageContextType;
  templateName: TemplateName;
}> = memo(({ children, pageContext, templateName }) => {
  if (!pageContext.intl) {
    return <Fragment>{children}</Fragment>;
  }

  const nextPageContext = {
    ...pageContext,
    templateName,
  } as PageContextFromHook;

  return (
    <PageContext.Provider value={nextPageContext}>
      {children}
    </PageContext.Provider>
  );
});

const usePageContext = (): PageContextFromHook => {
  const pageContext = useContext(PageContext);
  return pageContext;
};

export default usePageContext;
