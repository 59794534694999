import { ThemeSpec } from "@boxoffice/screenplay";
import { Property } from "csstype";
import { ColorModesScale, useThemeUI as useThemeUIOriginal } from "theme-ui";

export type ColorValue = Property.Color | ColorModesScale["background"];

export type Color = {
  text: ColorValue;
  primary: ColorValue;
  secondary: ColorValue;
  accent: ColorValue;
  muted: ColorValue;
  highlight: ColorValue;
  background: ColorValue;
  initialBackground: ColorValue;
  headerBackground: ColorValue;
  footerBackground: ColorValue;
};

export type EnhancedThemeSpec = ThemeSpec & {
  colors?: Color;
  rawColors?: Color;
};

interface Context extends ReturnType<typeof useThemeUIOriginal> {
  theme: EnhancedThemeSpec;
}

const useThemeUI = (): Context => {
  const themeUI = useThemeUIOriginal();

  return {
    ...themeUI,
    theme: themeUI.theme as EnhancedThemeSpec,
  };
};

export default useThemeUI;
