/* eslint-disable @typescript-eslint/ban-ts-comment */
import { responsive, theme, ThemeSpec } from "@boxoffice/screenplay";
import { ANIMATION_DURATION } from "shared/constants";
import {
  getMostReadable,
  getMostVisible,
  getTinyColor,
} from "shared/helpers/colors";
import { ThemeUIStyleObject } from "theme-ui";

const customScrollbar: ThemeUIStyleObject = {
  "&::-webkit-scrollbar": {
    width: "14px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: (theme) =>
      getMostVisible(theme.rawColors?.background, [
        theme.rawColors?.primary,
      ]).toHexString(),
    borderColor: "background",
    borderRadius: "maximum",
    borderStyle: "solid",
    borderWidth: "medium",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "primary",
  },
};

const COMMON_BUTTON_STYLES: ThemeUIStyleObject = {
  alignItems: "center",
  border: "none",
  borderColor: "transparent",
  borderRadius: "maximum",
  borderStyle: "solid",
  borderWidth: "medium",
  cursor: "pointer",
  display: "inline-flex",
  fontSize: 1,
  fontWeight: "bold",
  justifyContent: "center",
  lineHeight: "body",
  minHeight: "3rem",
  paddingX: "22px",
  paddingY: 2,
  textAlign: "center",
  textDecoration: "none",
  textTransform: "uppercase",
  transition: `all ${ANIMATION_DURATION}`,
};

const themeOverride: ThemeSpec = {
  ...theme,
  lineHeights: {
    body: 1.65,
    heading: 1.25,
  },
  breakpoints: ["640px", "768px", "1024px", "1280px", "1600px"],
  sizes: {
    container: "100%",
    containerGutter: responsive({
      xs: "10px",
      sm: "10px",
      md: "12px",
      lg: "66px",
      xl: "80px",
      xxl: "80px",
    }),
    sectionVerticalGutter: responsive({ xs: 4, lg: 5 }),
    sectionVerticalGutterAlt: responsive({ xs: 3, lg: 4 }),
    sectionVerticalReset: responsive({ xs: 0, lg: 0 }),
  },
  zIndices: {
    frame: 100,
  },
  buttons: {
    ...theme.buttons,
    primary: {
      ...COMMON_BUTTON_STYLES,
      backgroundColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      color: (theme) => {
        const backgroundColor = getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString();

        return getMostReadable(backgroundColor).toHexString();
      },
      "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
        textDecoration: "none",
        outline: "none",
        backgroundColor: (theme) => theme.rawColors?.accent,
        color: (theme) =>
          getMostReadable(theme.rawColors?.accent).toHexString(),
      },
    },
    secondary: {
      ...COMMON_BUTTON_STYLES,
      borderStyle: "solid",
      borderWidth: "medium",
      backgroundColor: (theme) => theme.rawColors?.background,
      color: (theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
        textDecoration: "none",
        borderColor: (theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.accent,
          ]).toHexString(),
      },
      "&[disabled]": {
        pointerEvents: "none",
        color: (theme) => {
          return getMostReadable(theme.rawColors?.background)
            .setAlpha(0.5)
            .toRgbString();
        },
        "& > *": {
          opacity: 0.5,
        },
      },
    },
  },
  layout: {
    ...theme.layout,
    container: {
      maxWidth: responsive({
        xs: "100%",
        sm: "620px",
        md: "744px",
        lg: "892px",
        xl: "1120px",
        xxl: "1440px",
      }),
      paddingX: responsive({
        xs: "10px",
        sm: "0px",
        md: "0px",
        lg: "0px",
        xl: "0px",
        xxl: "0px",
      }),
      width: "100%",
    },
    // @ts-expect-error FIXME: fix type
    containerNarrow: {
      ...theme.layout?.container,
      width: "100%",
      maxWidth: responsive({
        xs: "100%",
        sm: "640px",
        md: "768px",
        lg: "512px",
        xl: "640px",
        xxl: "768px",
      }),
    },
  },
  forms: {
    ...theme.forms,
    label: {
      ...theme.forms?.label,
      display: "block",
      fontSize: 1,
      fontWeight: "bold",
      lineHeight: "body",
      marginBottom: 1,
    },
    input: {
      ...theme.forms?.input,
      backgroundColor: "white",
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      borderRadius: "medium",
      borderWidth: "medium",
      color: "#000",
      fontFamily: "body",
      fontSize: 2,
      height: "3rem",
      lineHeight: "body",
      paddingX: 3,
      width: "100%",
      "&:focus": {
        outline: "none",
        borderColor: (theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.primary,
          ]).toHexString(),
      },
      "&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus": {
        boxShadow: "none",
      },
    },
    select: {
      backgroundColor: "white",
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      borderRadius: "medium",
      borderWidth: "medium",
      fontSize: 2,
      height: "3rem",
      lineHeight: "body",
      paddingX: 3,
      width: "100%",
      "&:focus": {
        outline: "none",
        borderColor: (theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.primary,
          ]).toHexString(),
      },
    },
    textarea: {
      ...theme.forms?.textarea,
      backgroundColor: "white",
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      borderRadius: "medium",
      borderWidth: "medium",
      color: "#000",
      fontFamily: "body",
      fontSize: 2,
      lineHeight: "body",
      "&:focus": {
        outline: "none",
        borderColor: (theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.primary,
          ]).toHexString(),
      },
    },
    theaterSelectTriggerWrapper: {
      flex: 1,
      position: "relative",
    },
    theaterSelectTrigger: {
      variant: "buttons.secondary",
      width: "100%",
      borderRadius: "medium",
      textAlign: "left",
      backgroundColor: (theme) => theme.rawColors?.background,
      color: (theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
        color: (theme) =>
          getMostReadable(theme.rawColors?.background).toHexString(),
        borderColor: (theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.accent,
          ]).toHexString(),
      },
    },
    theaterSelectDropdown: {
      backgroundColor: "background",
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      borderRadius: "medium",
      borderStyle: "solid",
      borderWidth: "medium",
      boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
      color: (theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      fontSize: 3,
      left: 0,
      maxHeight: "300px",
      overflowY: "auto",
      padding: 2,
      position: "absolute",
      textTransform: "none",
      width: "100%",
      zIndex: 2001,
      ...customScrollbar,
    },
    theaterSelectDropdownOption: {
      borderRadius: "medium",
      cursor: "pointer",
      paddingX: 3,
      paddingY: 2,
      "&:hover": {
        backgroundColor: (theme) => {
          const bg = getTinyColor(theme.rawColors?.background);
          return bg.isLight()
            ? bg.shade(10).toHexString()
            : bg.tint(10).toHexString();
        },
      },
    },
  },
  siteWrapper: {
    backgroundColor: "background",
    boxShadow: `0 6px 24px rgba(0,0,0,.15)`,
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    // margin: "0 auto",
    // maxWidth: "1600px",
  },
  styles: {
    root: {
      backgroundColor: (theme) => {
        const bg = getTinyColor(theme.rawColors?.background);
        return bg.isLight()
          ? bg.tint(50).toHexString()
          : bg.shade(50).toHexString();
      },
      boxSizing: "border-box",
      color: "text",
      fontFamily: "body",
      fontSize: responsive({ xs: 1, md: 2 }),
      fontWeight: "body",
      height: "100%",
      lineHeight: "body",
      margin: 0,
      overflowX: "hidden",
      overflowY: "scroll",
      padding: 0,
      wordWrap: "break-word",
      hyphens: "auto",
    },
    h1: {
      color: "currentColor",
      fontFamily: "heading",
      fontSize: responsive({ xs: 5, lg: 6 }),
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 0,
      marginTop: 0,
    },
    h2: {
      color: "currentColor",
      fontFamily: "heading",
      fontSize: responsive({ xs: 4, lg: 5 }),
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 0,
      marginTop: 0,
    },
    h3: {
      color: "currentColor",
      fontFamily: "heading",
      fontSize: responsive({ xs: 3, lg: 4 }),
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 0,
      marginTop: 0,
    },
    h4: {
      color: "currentColor",
      fontFamily: "heading",
      fontSize: responsive({ xs: 2, lg: 3 }),
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 0,
      marginTop: 0,
    },
    h5: {
      color: "currentColor",
      fontFamily: "heading",
      fontSize: responsive({ xs: 1, lg: 2 }),
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 0,
      marginTop: 0,
    },
    h6: {
      color: "currentColor",
      fontFamily: "heading",
      fontSize: responsive({ xs: 0, lg: 1 }),
      fontWeight: "heading",
      lineHeight: "heading",
      marginBottom: 0,
      marginTop: 0,
    },
    a: {
      color: "currentColor",
      textDecoration: "none",
      "&:hover, &:focus": {
        textDecoration: "underline",
      },
    },
    p: {
      color: "currentColor",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      margin: 0,
      paddingTop: 0,
      marginBottom: "1em",
      breakInside: "avoid",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    img: {
      maxWidth: "100%",
      display: "block",
    },
    hr: {
      borderWidth: "small",
      borderColor: (theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
      borderStyle: "solid",
      margin: 0,
    },
    ul: {
      listStyle: "none",
      marginBottom: 3,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      padding: 0,
      "& li": {
        marginBottom: 2,
        paddingLeft: "1.5em",
        position: "relative",
        wordBreak: "break-word",
        "&::before": {
          color: "text",
          content: '"—"',
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          lineHeight: 1,
          position: "absolute",
        },
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
    ol: {
      counterReset: "orderedList",
      listStyle: "none",
      marginBottom: 3,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      padding: 0,
      "& li": {
        counterIncrement: "orderedList",
        marginBottom: 2,
        paddingLeft: "2.5em",
        position: "relative",
        wordBreak: "break-word",
        "&::before": {
          fontWeight: "bold",
          color: "text",
          content: "counter(orderedList) ' —'",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          lineHeight: 1,
          position: "absolute",
        },
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
  },
};

export default themeOverride;
