export const CMP_ID = "e23a01f6-a508-4e71-8f50-c1a9cae7c0d0";
export const WBADS_NETWORK = 120157152;
export const WBADS_SITE: Record<string, string> = {
  fr: "WMP_FR_WEB",
  us: "WMP_US_WEB",
  uk: "WMP_UK_WEB",
  mx: "WMP_MX_WEB",
};
export const WBADS_GTM_ID = "GTM-WG3SK3P";
export const WBADS_AUTHORIZED_DIGITAL_SELLERS_FILE_URL = `https://cdn.api.getjad.io/adstxt/${WBADS_NETWORK}-WEB`;
