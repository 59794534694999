import React, { memo } from "react";
import { createClient, Provider } from "urql";

import { ALLOCINEAPI_TOKEN, ALLOCINEAPI_URL } from "../../constants";

interface Props {
  children: React.ReactNode;
  token?: string;
  url?: string;
}

const URQLProvider: React.FC<Props> = ({
  children,
  token = ALLOCINEAPI_TOKEN,
  url = ALLOCINEAPI_URL,
}) => {
  const client = createClient({
    url,
    fetchOptions: () => {
      return {
        headers: { authorization: token ? `Bearer ${token}` : "" },
      };
    },
  });

  return <Provider value={client}>{children}</Provider>;
};

export default memo(URQLProvider);
