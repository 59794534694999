import { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";
import { CookiesProvider } from "shared/helpers/cookie";
import { PageContext } from "shared/types/page";

export const wrapPageElement = ({
  element,
}: WrapPageElementBrowserArgs<unknown, PageContext>): React.ReactNode => {
  return <CookiesProvider>{element}</CookiesProvider>;
};
