import { PageContext } from "./types/page";

export const DEFAULT_PERIOD_FILTERS = [
  "TODAY",
  "TOMORROW",
  "ALL_WEEK",
  "NEXT_WEEK",
] as const;

export const ALLOCINEAPI_URL = "https://graph.allocine.fr/v1/wmp_api";
export const ALLOCINEAPI_STAGING_URL =
  "https://staging-graph.allocine.fr/v1/wmp_api/";
export const ALLOCINEAPI_TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NDk1Mjc4ODAsInVzZXJuYW1lIjoiYW5vbnltb3VzIiwidXVpZCI6IjY0OTVlMjZlLTJkMDMtNGM4My05MDdhLWE3MzMxMTUyOWJmZSIsImFwcGxpY2F0aW9uX25hbWUiOiJ3bXBfYXBpIiwiZXhwIjoxODY1MTEzMjAwfQ.WwNhOxGKcuLZSQ4baYhSNI6fGg_Ozyex5SgTRO-OKIy5JtBL0zPahDnazbeynQzQ2n9xROO6usn65omc0h-X3_2xG_TiNyxLumZtonk8qf_PCkfgb-HYI9spyTkBkcmZKHPFvSCZjj0g3AqYRTYqeTOZVoZOqVCzeX8hUwYciHWPi42DaYS3kJZsgug3TeTnNCl6FBibvlmNuy6w0JJiSlpj6zkexSdYXvrWDvjG7B2YxwIteUl6SeCfrDc91K-SGwxBkSdtYrgCEz0hpynEpzy-BNfX9TLXBQ96pw4LD3k1yC57CXK_8wH9QO_1nt-PY-_fctCPZ9Pjbh2p_5pBQ3GgREP2nji_rsUDKHB8NV69rVYF_RCUaVRHLyPEg26-vTWIqMiifw4gAAKE223KPF1_W0reTTNk4t_deU1CzgiTd3VY2u-CI_moqylmfQCAMcY7tGTZXUMYZX3ZK9YY_lBBYYaJTdUVpIWwTjAZrYROTlCMig05uu1xdwa3WH5cNvTGwaOMb6PpbGIJ20QiqjAurt72gVMcbLElIV1urj-afYJ5VjWdAh_2kyZ4dPUaLd9YmUCElquGwPBovlhk1ZgwWnFPXgNXT7pZaYyKYxNryuZnLVjVkabCvenYNOXK4WT2KHEd8267HCr_6dBr3zVh-6CIgmYyrMmB-H7Abaw";
export const ALLOCINEAPI_STAGING_TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NDk1Mjc3MTYsInVzZXJuYW1lIjoiYW5vbnltb3VzIiwidXVpZCI6IjA3ZDQ4YmNkLWYyZjktNDZkMS1hNjAwLWI1MzU5YTNlMDU4MCIsImFwcGxpY2F0aW9uX25hbWUiOiJ3bXBfYXBpIiwiZXhwIjoxODY1MTEzMjAwfQ.dUKYX5qEy49PinaFAepju_5zGS_cXKQp_tlbtYMLmpu-LrV9L_3JWfeH5yd-Mudxkbtnmt2NPMjO4x4bt7EEf9Tto9ndy51jnRlXT1fl3s93TQMCRTe4ThAqOz_8F0RrVC7wl2RDmXdl4G5hcObpLjll-1szgqM04z66dWkHtKg3hU_v6QCGh9CJC6eFS1aKw-D7fWIO_XHdWnZsgwfNgXACM4C5xPo1vz6t9yiNixaG3PDwgmQRaMeZDlzXr83S6UjFyHce0APqsWu0UX-mPv-mcL4-ujAeeu3oyM0O_dHto_lAiORsZQ4VmgZg94zg9hOZwUtBqVqxFke7Ge7qmliFjzGoHandqjGeCLxiZG6utJ6gzIudZRY-2E1E5C8gl9KDFQB1wVoA4hj2z2TQPQsOJPT5oEsTBlxztPo8bmi-u30dQztMdadEd_OFpJv-nbVlaFNCwpZ7oiHLMzJIUmcKHD1_c5xrPMNtQcIlt0LhawEDETMNyauK-FH3M3Ptwp7VYwR5ep4WnkEtQ9t2E-eGAKkrFQ6OhkcyYwoElBwWFuuo5PVaURoh_GAhKZIuYSFkzu_3R8w9miHr5UVQoUicmz9xKZiJp6q2_elNdEYYuEyNoPC-5jx1Fdn35pu-5cO9wBQuNrd5gxBRx_5DFdHzljUG0PLbiU7V79jMvjM";
export const PERMALINK_PREFIX = "permalink";
export const MOVIES_PATH_PREFIX = "movies";
export const EVENTS_PATH_PREFIX = "events";
export const THEATERS_PATH_PREFIX = "theaters";
export const ALLOWED_LOCALES = ["en-US", "fr-FR", "de-DE", "es-MX"];

export const FALLBACK_LOCALE = "en-US";
export const FALLBACK_SIMPLIFIED_LOCALE = "en";
export const FALLBACK_SHOWTIME_WEEK_RELEASE_DAY = 5;
export const FALLBACK_TIME_ZONE = "UTC";
export const FALLBACK_COUNTRY_CODE = "us";
export const FALLBACK_ISO31661A2 = "US";
export const FALLBACK_ISO31661A3 = "USA";

export const FALLBACK_PAGE_CONTEXT: PageContext = {
  intl: {
    locale: {
      short: FALLBACK_SIMPLIFIED_LOCALE,
      long: FALLBACK_LOCALE,
    },
    country: {
      code: FALLBACK_COUNTRY_CODE,
      iso31661A2: FALLBACK_ISO31661A2,
      iso31661A3: FALLBACK_ISO31661A3,
    },
    messages: {},
  },
};

export const PARALLEL_EVENTS_GETTER_COUNT = 100;
export const PARALLEL_EVENTS_CREATOR_COUNT = 100;
export const PARALLEL_MOVIES_GETTER_COUNT = 100;
export const PARALLEL_MOVIES_CREATOR_COUNT = 100;
export const PARALLEL_THEATERS_PRACTICAL_INFO_GETTER_COUNT = 100;
export const PARALLEL_THEATERS_CREATOR_COUNT = 100;
export const PARALLEL_ATTRIBUTES_GETTER_COUNT = 100;
export const PARALLEL_ATTRIBUTES_CREATOR_COUNT = 100;

export const ANIMATION_DURATION = "300ms";

export const BOOST_TICKETING_DISTINCTIVE_SLUG = "startticketing";
export const BOOST_TICKETING_SESSION_REDIRECT_PATH =
  "api/startticketing/redirect";
export const BOOST_TICKETING_SESSION_REDIRECT_URL_PARAM = "requestData";
export const BOOST_TICKETING_LOGIN_REDIRECT_URL_PARAM = "return";
export const BOOST_TICKETING_PASSWORD_RESET_EMAIL_URL_PARAM = "email";
export const BOOST_TICKETING_PASSWORD_RESET_CODE_URL_PARAM = "resetCode";
export const BOOST_TICKETING_PASSWORD_RESET_DEFAULT_CODE = "{0}";
