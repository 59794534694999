import { ThemeSpec } from "@boxoffice/screenplay";
import templateTheme from "gatsby-theme-fincher-template/src/gatsby-plugin-theme-ui";
import { getMostReadable } from "shared/helpers/colors";
import { EnhancedThemeSpec } from "shared/hooks/useThemeUI";
import { PluginOptions } from "shared/types/config";

import settingsJson from "../../settings.json";

const settings = settingsJson as unknown as PluginOptions;
const { theme: settingsTheme } = settings;

const getFonts = (key: "body" | "heading"): string =>
  [settingsTheme?.fonts?.[key], templateTheme.fonts[key]]
    .filter(Boolean)
    .join(", ");

const background =
  settingsTheme?.colors?.palette?.background ||
  settingsTheme?.colors?.brand6 ||
  "#000";
const headerBackground =
  settingsTheme?.colors?.palette?.headerBackground ||
  settingsTheme?.colors?.brand2 ||
  "#000";
const footerBackground =
  settingsTheme?.colors?.palette?.footerBackground ||
  settingsTheme?.colors?.brand3 ||
  "#000";
const primary =
  settingsTheme?.colors?.palette?.primary ||
  settingsTheme?.colors?.brand1 ||
  "#000";
const secondary =
  settingsTheme?.colors?.palette?.primary ||
  settingsTheme?.colors?.brand1 ||
  "#000";
const accent =
  settingsTheme?.colors?.palette?.accent ||
  settingsTheme?.colors?.brand5 ||
  "#000";
const highlight =
  settingsTheme?.colors?.palette?.highlight ||
  settingsTheme?.colors?.brand3 ||
  "#000";
const muted =
  settingsTheme?.colors?.palette?.muted ||
  settingsTheme?.colors?.brand4 ||
  "#000";
const text = getMostReadable(background).toHexString();

const colors: EnhancedThemeSpec["colors"] = {
  primary,
  secondary,
  accent,
  highlight,
  muted,
  background,
  initialBackground: background,
  headerBackground,
  footerBackground,
  text,
};

const theme: ThemeSpec = {
  ...templateTheme,
  colors,
  fonts: {
    ...templateTheme.fonts,
    heading: getFonts("heading"),
    body: getFonts("body"),
  },
  fontWeights: {
    ...templateTheme.fontWeights,
    heading:
      settingsTheme?.fontWeights?.heading || templateTheme.fontWeights.heading,
    body: settingsTheme?.fontWeights?.body || templateTheme.fontWeights.body,
    bold: settingsTheme?.fontWeights?.bold || templateTheme.fontWeights.bold,
  },
  textStyles: {
    ...templateTheme.textStyles,
    heading: {
      ...templateTheme.textStyles?.heading,
      fontStyle: settingsTheme?.fontStyles?.heading,
    },
  },
};

export default theme;
