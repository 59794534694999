import { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";
import { PageContext } from "shared/types/page";

import Wrapper from "./Wrapper";

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<unknown, PageContext>): React.ReactNode => {
  return <Wrapper pageContext={props.pageContext}>{element}</Wrapper>;
};
