import React, { Fragment, memo } from "react";

import { ADSContainer } from "./src/components/ADS";

const Wrapper: React.FC<unknown> = ({ children }) => {
  return (
    <Fragment>
      <ADSContainer.Interstitial />
      <ADSContainer.Header />
      {children}
    </Fragment>
  );
};

export default memo(Wrapper);
