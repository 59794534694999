import React, { memo, useEffect, useRef, useState } from "react";
import { AnalyticsContextProvider } from "shared/hooks/useAnalytics";
import { PluginOptionsTrackers } from "shared/types/config";

import { getConsents, initTrackers } from "./helpers";

interface Props {
  children: React.ReactNode;
  trackers?: PluginOptionsTrackers;
}

const Wrapper: React.FC<Props> = ({ children, trackers }) => {
  const [consents, setConsents] = useState<boolean | undefined>(undefined);
  const isMountedRef = useRef<boolean>(false);

  // Wait for the consents from the Consent Management Platform
  // The CMP is currently loaded by the gatsby-theme-ads module
  useEffect(() => {
    isMountedRef.current = true;

    if (!window.didomiOnReady) {
      window.didomiOnReady = [];
    }

    window.didomiOnReady.push((didomiInstance) => {
      const initialConsents = getConsents(didomiInstance);

      if (initialConsents && isMountedRef.current) {
        setConsents(initialConsents);
      }

      didomiInstance?.on?.("consent.changed", () => {
        const nextConsents = getConsents(didomiInstance);
        if (isMountedRef.current) {
          setConsents(nextConsents);
        }
      });
    });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Init trackers if consents are ok
  useEffect(() => {
    if (consents !== undefined) {
      initTrackers(trackers, consents);
    }
  }, [consents]);

  return <AnalyticsContextProvider>{children}</AnalyticsContextProvider>;
};

export default memo(Wrapper);
