import { useEffect } from "react";

import {
  ColorScheme,
  ColorSchemeMessageData,
  LifeCycleMessageData,
} from "./types";

const useColorSchemePreview = (
  onColorSchemeReceived: (colorScheme: ColorScheme) => void
): void => {
  const receiveMessage = (
    message: MessageEvent<ColorSchemeMessageData | null | undefined>
  ) => {
    if (
      message.data?.type === "colorScheme" &&
      message.data?.payload?.colorScheme
    ) {
      onColorSchemeReceived(message.data.payload.colorScheme);
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage);

    if (window.opener) {
      const lifeCyclePayload: LifeCycleMessageData = {
        type: "lifeCycle",
        lifeCycle: "mounted",
      };

      window.opener.postMessage(lifeCyclePayload, "*");
    }

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);
};

export default useColorSchemePreview;
