import React, { memo, useContext } from "react";

import { AnalyticsCustomDimension } from "../types/config";

type AnalyticsEvent = "click" | "play";
type AnalyticsCategory = "Schedule" | "Video" | "Navigation" | "Content";

interface AnalyticsContext {
  track: (
    /**
     * The type of interaction (e.g. 'click')
     * */
    eventName: AnalyticsEvent,
    payload: {
      /**
       * Typically the object that was interacted with (e.g. 'Video')
       * */
      category: AnalyticsCategory;
      /**
       * Useful for categorizing events (e.g. 'Fall Campaign')
       */
      label: string;
      /**
       * A numeric value associated with the event (e.g. 42)
       */
      value?: number;
    } & Partial<Record<AnalyticsCustomDimension, string>>
  ) => void;
}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  track: () => undefined,
});

export const AnalyticsContextProvider: React.FC<Record<string, unknown>> = memo(
  ({ children }) => {
    return (
      <AnalyticsContext.Provider
        value={{
          track: (eventName, payload) => {
            if (typeof window !== "undefined") {
              window.Analytics.track(eventName, payload);
            }
          },
        }}
      >
        {children}
      </AnalyticsContext.Provider>
    );
  }
);

const useAnalytics = (): AnalyticsContext => {
  return useContext(AnalyticsContext);
};

export default useAnalytics;
